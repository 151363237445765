export default {
  "cell": "qCI",
  "cell-label-container": "qCt",
  "cell-label": "qCM",
  "overflow": "qCP",
  "sub-label": "qCl body-2",
  "overdue": "qCX",
  "file-name-warning": "qCC",
  "missing": "qCk",
  "warning-icon": "qCJ",
  "flex-label": "qCs",
  "with-separator": "qCF",
  "self-invoice-icon": "qCg",
  "tooltip-wrapper": "qCD"
};
