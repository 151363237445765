export default {
  "request-expense-report-validation": "qcK",
  "account-selector": "qcG",
  "has-error": "qcr",
  "actions": "qcb",
  "action-btn": "qcy",
  "action-btn-success": "qcU",
  "warnings": "qcj",
  "top-border": "qcW",
  "account-selector-subtitle": "qcu",
  "action-btn-right": "qqS",
  "funds-disclaimer": "qqc",
  "account-options": "qqq",
  "account-balance": "qqZ"
};
