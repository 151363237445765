export default {
  "section": "cYX",
  "header-section": "cYC",
  "header-title": "cYk",
  "header-item": "cYJ",
  "item": "cYs",
  "center-align": "cYF",
  "content-gap": "cYg",
  "cta-footer": "cYD",
  "section-content": "cYV"
};
