export default {
  "wrapper": "cKl",
  "tabs": "cKX",
  "tabs-nav": "cKC",
  "tabs-panel": "cKk",
  "tabs-panel-wrapper": "cKJ",
  "list-head": "cKs",
  "list": "cKF",
  "footer": "cKg",
  "reward-amount": "cKD",
  "rewarded": "cKV",
  "registered": "cKL",
  "disclaimer": "cKT",
  "empty-state-wrapper": "cKz",
  "empty-state": "cKa",
  "illustration": "cKH",
  "spinner": "cKO",
  "total-earned": "cKm"
};
