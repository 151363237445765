export default {
  "modal": "qXT",
  "action-container": "qXz",
  "similar-label": "qXa",
  "info-icon": "qXH",
  "container": "qXO",
  "subtitle": "qXm caption-bold",
  "error": "qXp",
  "item-container": "qXx"
};
