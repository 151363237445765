export default {
  "container": "ciQ",
  "title-and-trash": "cie",
  "trash": "ciB",
  "quantity-amount": "ciE",
  "flex-row": "cid",
  "quantity": "ciI",
  "quantity-input": "cit",
  "border-left": "ciM",
  "dropdown": "ciP",
  "item-row": "cil",
  "unit-price": "ciX",
  "price-input": "ciC",
  "hidden": "cik",
  "d-none": "ciJ",
  "dropdown-option": "cis",
  "list-row": "ciF",
  "row-title": "cig",
  "row-price": "ciD body-2",
  "row-internal-note": "ciV",
  "with-internal-note": "ciL",
  "dropdown-content": "ciT",
  "select-item": "ciz"
};
