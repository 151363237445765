export default {
  "progress-container": "qFs",
  "dashed-line": "qFF",
  "solid-line": "qFg",
  "circle-container": "qFD",
  "circle": "qFV",
  "current-step-dot": "qFL",
  "last-step-dot": "qFT",
  "success-icon": "qFz",
  "rejected-icon": "qFa"
};
