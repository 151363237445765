export default {
  "attachment-viewer": "cye",
  "hide-sidebar": "cyB",
  "sidebar": "cyE",
  "header": "cyd",
  "header-top": "cyI",
  "header-icon": "cyt",
  "header-amount": "cyM",
  "infobox": "cyP",
  "vat": "cyl",
  "validation": "cyX"
};
