export default {
  "section-container": "cUt",
  "disclaimer-section": "cUM",
  "trip-title": "cUP caption-bold mb-16",
  "google-link-container": "cUl",
  "link-icon": "cUX",
  "trip-details": "cUC body-2",
  "trip-details-icon": "cUk",
  "trip-details-title": "cUJ",
  "subtitle": "cUs caption-bold mb-16",
  "disclaimer": "cUF",
  "visualizer": "cUg"
};
