export default {
  "section-container": "cre",
  "disclaimer-section": "crB",
  "trip-title": "crE caption-bold mb-16",
  "google-link-container": "crd",
  "link-icon": "crI",
  "trip-details": "crt body-2",
  "trip-details-icon": "crM",
  "trip-details-title": "crP",
  "subtitle": "crl caption-bold mb-16",
  "disclaimer": "crX",
  "visualizer": "crC"
};
