export default {
  "wrapper": "cuF",
  "request-header": "cug",
  "request": "cuD",
  "scheduled": "cuV",
  "scheduled-label": "cuL",
  "note": "cuT",
  "note-label": "cuz",
  "note-label-danger": "cua",
  "note-content": "cuH",
  "status": "cuO",
  "transfer-amount": "cum"
};
