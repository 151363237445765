export default {
  "header": "qZQ",
  "header-type": "qZe",
  "close": "qZB",
  "close-icon": "qZE",
  "header-infos": "qZd",
  "title": "qZI body-1",
  "declined": "qZt",
  "canceled": "qZM",
  "approved": "qZP",
  "date": "qZl body-2",
  "container": "qZX",
  "picto": "qZC",
  "request": "qZk",
  "picto-status": "qZJ",
  "check": "qZs",
  "stop": "qZF",
  "warning": "qZg",
  "general": "qZD",
  "amount": "qZV",
  "counterparty": "qZL body-2",
  "initiator": "qZT",
  "initiator-avatar": "qZz",
  "avatar-image": "qZa",
  "initiator-id": "qZH",
  "name": "qZO body-2",
  "role": "qZm caption-bold",
  "infobox": "qZp",
  "account-infobox": "qZx qZp",
  "beneficiary-warning": "qZw caption-bold",
  "dates-wrapper": "qZN",
  "dates": "qZv",
  "recurrence": "qZo caption-bold",
  "arrow": "qZn",
  "notify-checkbox": "qZi"
};
