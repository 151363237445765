export default {
  "numbering-container": "cnh",
  "automatic-number-label": "cnf",
  "icon-tooltip": "cnK",
  "fix-width": "cnG",
  "dates-container": "cnr",
  "with-performance-date": "cnb",
  "date-picker": "cny",
  "label": "cnU",
  "header-text-field-container": "cnj",
  "mt-16": "cnW",
  "header-text-field": "cnu",
  "header-text-field-close-button": "ciS"
};
