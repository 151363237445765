export default {
  "wrapper": "cKW",
  "tabs": "cKu",
  "tabs-nav": "cGS",
  "tabs-panel-wrapper": "cGc",
  "list-head": "cGq",
  "list": "cGZ",
  "footer": "cGR",
  "reward-amount": "cGQ",
  "earned": "cGe",
  "pending": "cGB",
  "empty-state-wrapper": "cGE",
  "empty-state": "cGd",
  "illustration": "cGI",
  "error-wrapper": "cGt",
  "error-illustration": "cGM",
  "error-description": "cGP",
  "spinner": "cGl",
  "total-earned": "cGX"
};
