export default {
  "row": "cjL",
  "active": "cjT",
  "dot": "cjz",
  "red": "cja",
  "green": "cjH",
  "yellow": "cjO",
  "status": "cjm",
  "align-right": "cjp",
  "animated": "cjx",
  "fadein-item": "cjw",
  "cell": "cjN body-2",
  "cell-content": "cjv",
  "amount": "cjo body-2",
  "subtitle": "cjn caption",
  "padding-left": "cji",
  "padding-right": "cjA",
  "note": "cjY"
};
