export default {
  "modal": "qdN",
  "container": "qdv",
  "form-container": "qdo",
  "preview-container": "qdn",
  "preview-content": "qdi",
  "switch-container": "qdA",
  "supplier-invoice-preview": "qdY",
  "header": "qdh",
  "footer": "qdf",
  "form-content": "qdK",
  "form-content-section": "qdG",
  "form-disclaimer": "qdr",
  "hidden": "qdb"
};
