export default {
  "gap-32": "coj",
  "fieldset": "coW",
  "date-picker": "cou",
  "with-performance-date": "cnS",
  "mt-4": "cnc",
  "clients": "cnq",
  "dropdown-option": "cnZ",
  "dropdown-content": "cnR",
  "select-client": "cnQ",
  "client-option": "cne",
  "icon-tooltip": "cnB",
  "payment-details": "cnE",
  "dates-container": "cnd",
  "automatic-number-label": "cnI",
  "label": "cnt",
  "numbering-container": "cnM",
  "fix-width": "cnP",
  "customer-error": "cnl",
  "amount-text": "cnX",
  "header-text-field-container": "cnC",
  "header-text-field": "cnk",
  "header-text-field-close-button": "cnJ",
  "mt-16": "cns"
};
