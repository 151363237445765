export default {
  "card-sidebar-header": "qSV",
  "content": "qSL",
  "header-type": "qST",
  "close": "qSz",
  "close-icon": "qSa",
  "header-infos": "qSH",
  "title": "qSO body-1",
  "danger": "qSm",
  "success": "qSp",
  "warning": "qSx",
  "date": "qSw body-2",
  "card-image-container": "qSN",
  "card-image": "qSv",
  "info-container": "qSo",
  "spending-reason": "qSn",
  "spending-reason-label": "qSi body-2 mr-8",
  "spending-reason-note": "qSA body-2"
};
