export default {
  "item-wrapper": "qMi",
  "title-sub-wrapper": "qMA",
  "icon-title-wrapper": "qMY",
  "icon": "qMh",
  "icon-wrapper": "qMf",
  "title": "qMK body-1",
  "subtitle": "qMG body-2",
  "amount": "qMr body-1"
};
