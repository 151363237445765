export default {
  "header-cell": "cwO",
  "checkbox-cell": "cwm",
  "title-col": "cwp",
  "internal-note-col": "cwx",
  "unit-col": "cww",
  "price-col": "cwN",
  "header-content": "cwv caption-bold",
  "active": "cwo",
  "empty": "cwn"
};
