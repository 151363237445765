export default {
  "invoice-sidebar": "cAX",
  "body": "cAC",
  "box": "cAk",
  "box-header": "cAJ",
  "footer": "cAs",
  "footer-button": "cAF",
  "footer-button-tooltip": "cAg",
  "sidebar-box": "cAD",
  "border-top": "cAV",
  "row": "cAL",
  "status-container": "cAT",
  "due-date-container": "cAz",
  "greyed-out": "cAa",
  "struck-through": "cAH",
  "overdue": "cAO",
  "primary-actions": "cAm cAD",
  "primary-action": "cAp",
  "danger-action": "cAx",
  "button-icon": "cAw",
  "button-label": "cAN body-2",
  "related-credit-note-title": "cAv caption-bold",
  "paid-at-placeholder": "cAo"
};
