export default {
  "form-content": "chr",
  "row": "chb",
  "numbering-format": "chy",
  "numbering-fields": "chU",
  "input-settings": "chj",
  "next-invoice-number": "chW",
  "spacing": "chu",
  "label-tooltip": "cfS",
  "tooltip-icon": "cfc",
  "customize-field": "cfq"
};
