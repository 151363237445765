/* import __COLOCATED_TEMPLATE__ from './cell.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import {
  FIRST_EINVOICE_TOOLTIP_DISMISSED_LOCAL_STORAGE_KEY,
  INVOICE_STATUSES,
} from 'qonto/constants/supplier-invoice';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { InstructionalTooltip } from 'qonto/react/components/product-discovery/instructional-tooltip';

export default class SupplierInvoicesTableCellComponent extends Component {
  InstructionalTooltip = InstructionalTooltip;

  @service intl;
  @service zendeskLocalization;

  @tracked isFirstFrenchEinvoiceTooltipDismissed = false;

  get shouldDisplayFirstFrenchEinvoiceTooltip() {
    let allowedStatus = [INVOICE_STATUSES.toReview, INVOICE_STATUSES.toPay];

    return (
      allowedStatus.includes(this.args.cell?.status) &&
      this.args.firstFrenchEinvoiceId === this.args.cell?.id &&
      this.args.cell?.title &&
      Boolean(!safeLocalStorage.getItem(FIRST_EINVOICE_TOOLTIP_DISMISSED_LOCAL_STORAGE_KEY))
    );
  }

  get showTooltipOrEInvoiceLabel() {
    let { isEInvoice, hasSelfInvoice } = this.args.cell;
    return isEInvoice || hasSelfInvoice;
  }

  get isStatus() {
    return Object.values(INVOICE_STATUSES).includes(this.args.cell);
  }

  get einvoicingFAQLink() {
    return this.intl.t('supplier-invoices.instructional-tooltip.einvoicing-disclaimer.url', {
      faqUrl: this.zendeskLocalization.getLocalizedArticle('fr-einvoice'),
    });
  }

  @action
  handleFirstFrenchEinvoiceTooltipClose() {
    this.isFirstFrenchEinvoiceTooltipDismissed = true;
    safeLocalStorage.setItem(FIRST_EINVOICE_TOOLTIP_DISMISSED_LOCAL_STORAGE_KEY, true);
  }
}
