export default {
  "attachment-viewer": "qSy",
  "hide-sidebar": "qSU",
  "sidebar": "qSj",
  "header": "qSW",
  "header-top": "qSu",
  "header-icon": "qcS",
  "header-amount": "qcc",
  "infobox": "qcq",
  "vat": "qcZ",
  "validation": "qcR"
};
