export default {
  "wrapper": "qdE",
  "content": "qdd",
  "title": "qdI",
  "aside": "qdt",
  "description": "qdM",
  "faq-link": "qdP",
  "close-btn": "qdl",
  "container": "qdX",
  "mandate-field-list": "qdC",
  "mandate-field-item": "qdk",
  "mandate-field-item__dot": "qdJ",
  "mandate-field-item__text": "qds"
};
