export default {
  "subtitle": "cWX",
  "green-text": "cWC",
  "balance-subtitle": "cWk",
  "options": "cWJ",
  "shadow": "cWs",
  "menu-item": "cWF body-2",
  "close-account": "cWg",
  "footer": "cWD",
  "progress": "cWV",
  "progress-bar": "cWL",
  "progress-bar-text": "cWT",
  "progress-active": "cWz"
};
