export default {
  "card": "cxt",
  "card-primary": "cxM",
  "content": "cxP",
  "content-primary": "cxl",
  "disabled": "cxX",
  "illustration": "cxC",
  "illustration-primary": "cxk",
  "illustration-disabled": "cxJ",
  "cta": "cxs",
  "dismiss-button": "cxF",
  "buttons": "cxg",
  "required-badge": "cxD"
};
