export default {
  "cell-content": "cuY",
  "icon": "cuh",
  "status_check-m": "cuf",
  "description": "cuK body-1",
  "from": "cuG body-2",
  "from--abort": "cur",
  "description--abort": "cub",
  "title": "cuy body-2"
};
