export default {
  "row": "qsv",
  "icon": "qso",
  "cell": "qsn",
  "cell-date": "qsi",
  "cell-amount": "qsA",
  "cell-status": "qsY",
  "cell-content": "qsh",
  "align-right": "qsf"
};
