export default {
  "credit-note-sidebar": "cvr",
  "body": "cvb",
  "box": "cvy",
  "box-header": "cvU",
  "footer": "cvj",
  "footer-button": "cvW",
  "sidebar-box": "cvu",
  "border-top": "coS",
  "row": "coc",
  "related-invoice-title": "coq caption-bold",
  "einvoice-rejected": "coZ"
};
