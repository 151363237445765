export default {
  "header-cell": "cfJ",
  "empty": "cfs",
  "header-content": "cfF caption-bold",
  "active": "cfg",
  "align-right": "cfD",
  "col-8": "cfV",
  "col-7": "cfL",
  "col-5": "cfT",
  "col-4": "cfz"
};
