export default {
  "label-select": "qtH",
  "puce": "qtO",
  "puce-wrapper": "qtm",
  "puce-container": "qtp",
  "readonly": "qtx",
  "label-display": "qtw",
  "label-list-name": "qtN body-1",
  "label-list-value": "qtv body-2"
};
