/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { STATUS } from 'qonto/constants/requests';

export default class RequestSidebarMileageHeaderComponent extends Component {
  @service intl;
  @service organizationManager;
  @service abilities;
  @service modals;
  @service segment;

  constructor() {
    super(...arguments);
    this.state = this.args.state || STATUS.PENDING;
  }

  get distance() {
    let distanceKm = (this.args.request.mileageCalculation.distanceMeters / 1000).toFixed(2);
    let rate = this.args.request.mileageCalculation.rate?.value;
    return {
      distanceKm,
      rate,
    };
  }

  get lastActionDate() {
    let { processedAt, createdAt } = this.args.request;
    return processedAt || createdAt;
  }

  get isUserReviewer() {
    let currentUserId = this.organizationManager.membership.id;
    let initiatorId = this.args.request.initiator.get('id');

    return currentUserId !== initiatorId;
  }

  get titleStatus() {
    let { approver, initiator, status } = this.args.request;
    let pastStatuses = [STATUS.CANCELED, STATUS.DECLINED, STATUS.APPROVED];

    if (pastStatuses.includes(status)) {
      let user = approver || initiator;
      let { firstName, lastName } = user.getProperties('firstName', 'lastName');
      let fullName = `${firstName.charAt(0)}. ${lastName}`;

      let statuses = {
        [STATUS.DECLINED]: this.intl.t('requests.sidebar.header.status.declined', {
          approver_name: fullName,
        }),
        [STATUS.APPROVED]: this.intl.t('requests.sidebar.header.status.accepted', {
          approver_name: fullName,
        }),
        [STATUS.CANCELED]: this.intl.t('requests.transfers.final_status.canceled', {
          name: fullName,
        }),
      };

      return statuses[status];
    }

    return this.isUserReviewer
      ? this.intl.t('labels.mileage')
      : this.intl.t('requests.sidebar.header.status.pending');
  }

  get titleClass() {
    return this.isUserReviewer && this.args.request.status === STATUS.PENDING
      ? ''
      : this.args.request.status;
  }

  get requestDate() {
    let { status } = this.args.request;
    let formattedDate = this.intl.formatDateRelative(this.lastActionDate);

    if (status === STATUS.PENDING) {
      return this.intl.t('checks.sidebar.requested_at') + ' ' + formattedDate;
    }

    if ([STATUS.DECLINED, STATUS.APPROVED].includes(status)) {
      return this.intl.t('requests.sidebar.header.updated-date', { updated_at: formattedDate });
    }

    return formattedDate;
  }

  get canSeeAmountModal() {
    let { organization } = this.organizationManager;
    return organization.isFrench && this.abilities.can('use mileage improvements request');
  }

  @action
  handleCalculationAmountClick() {
    this.segment.track('request_calculation-details_clicked', {
      origin: 'mileage_request',
    });

    this.modals.open('mileage/modals/mileage-calculation-details', {
      isFullScreenModal: true,
      amount: this.args.request.amount,
      mileageCalculation: this.args.request.mileageCalculation,
    });
  }
}
