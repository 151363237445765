export default {
  "supplier-selector-wrapper": "qlO",
  "select-supplier": "qlm",
  "dropdown": "qlp",
  "iban": "qlx",
  "card": "qlw body-2",
  "add-btn": "qlN btn btn--square btn--icon-only",
  "archived-card": "qlv",
  "title": "qlo body-1",
  "archived-title": "qln",
  "edit-supplier-tooltip": "qli",
  "add-supplier": "qlA btn btn--tertiary",
  "error": "qlY caption"
};
