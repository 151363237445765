export default {
  "header": "crk",
  "header-type": "crJ",
  "close": "crs",
  "close-icon": "crF",
  "header-infos": "crg",
  "title": "crD body-1",
  "declined": "crV",
  "canceled": "crL",
  "approved": "crT",
  "pending": "crz",
  "date": "cra body-2",
  "mileage-icon": "crH",
  "distance-subtitle": "crO body-2",
  "link-icon": "crm",
  "container": "crp",
  "picto": "crx",
  "general": "crw",
  "amount": "crN",
  "calculation-container": "crv",
  "counterparty": "cro body-2",
  "infobox": "crn"
};
