export default {
  "header": "qct",
  "header-type": "qcM",
  "close": "qcP",
  "close-icon": "qcl",
  "header-infos": "qcX",
  "title": "qcC body-1",
  "declined": "qck",
  "canceled": "qcJ",
  "approved": "qcs",
  "pending": "qcF",
  "date": "qcg body-2",
  "container": "qcD",
  "picto": "qcV",
  "picto-status": "qcL",
  "general": "qcT",
  "amount": "qcz",
  "counterparty": "qca body-2",
  "initiator": "qcH",
  "initiator-avatar": "qcO",
  "avatar-image": "qcm",
  "initiator-id": "qcp",
  "name": "qcx body-2",
  "role": "qcw caption-bold",
  "infobox": "qcN",
  "account-infobox": "qcv qcN",
  "beneficiary-warning": "qco caption-bold",
  "dates-wrapper": "qcn",
  "dates": "qci",
  "recurrence": "qcA caption-bold",
  "arrow": "qcY",
  "notify-checkbox": "qch"
};
