export default {
  "container": "cxO",
  "cards-container": "cxm",
  "card": "cxp",
  "expanded": "cxx",
  "hidden": "cxw",
  "collapsed": "cxN",
  "with-expand-collapse-animation": "cxv",
  "with-dismiss-animation": "cxo",
  "has-one-card": "cxn",
  "dismissing": "cxi",
  "with-hover-animation": "cxA",
  "toggle-button": "cxY",
  "toggle-container": "cxh",
  "toggle-button-icon": "cxf",
  "has-two-cards": "cxK"
};
