export default {
  "row": "cGK",
  "icon": "cGG",
  "cell": "cGr",
  "cell-amount": "cGb",
  "cell-status": "cGy",
  "cell-date": "cGU",
  "cell-content": "cGj",
  "align-right": "cGW"
};
