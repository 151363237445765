export default {
  "item": "qXw",
  "details": "qXN",
  "flex": "qXv",
  "header": "qXo qXv",
  "flex-no-gap": "qXn qXv",
  "file-name": "qXi",
  "label": "qXA body-2",
  "self-icon": "qXY",
  "with-separator": "qXh",
  "dimmed": "qXf"
};
