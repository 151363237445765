/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Badge } from '@repo/design-system-kit';
import dayjs from 'dayjs';

import {
  EDITABLE_DESCRIPTION_INVOICE_STATUSES,
  INVOICE_STATUSES,
  STATUS_COLORS,
} from 'qonto/constants/supplier-invoice';
import formats from 'qonto/formats';

export default class SupplierInvoicesDetailsSidebarHeaderComponent extends Component {
  badge = Badge;

  @service abilities;
  @service intl;
  @tracked invoiceStatuses = INVOICE_STATUSES;

  get amount() {
    let { value, currency } = this.args.invoice?.totalAmount || {};

    if (currency && value !== null && value !== undefined) {
      return this.intl.formatMoney(-value, {
        currency,
        signus: true,
      });
    }

    return `--\xA0${formats.number.EUR.currency}`;
  }

  get status() {
    let { invoice = {} } = this.args;
    let { status, paymentDate, scheduledDate } = invoice;

    if (status === INVOICE_STATUSES.scheduled) {
      return {
        dateLabel: this.intl.t('supplier-invoices.preview.operation-date'),
        date: scheduledDate,
      };
    }
    if (status === INVOICE_STATUSES.paid) {
      return {
        display: this.intl.t('supplier-invoices.preview.status.paid'),
        dateLabel: this.intl.t('supplier-invoices.preview.payment-date'),
        color: STATUS_COLORS.paid,
        date: paymentDate,
      };
    }
  }

  get hasDescription() {
    return Boolean(this.args.invoice?.description?.trim());
  }

  get isOverdue() {
    let { dueDate, status } = this.args.invoice || {};
    let canOverdue = dueDate && status !== INVOICE_STATUSES.paid;

    return canOverdue ? dayjs().isAfter(dueDate) : false;
  }

  get showStatusDate() {
    return [INVOICE_STATUSES.scheduled, INVOICE_STATUSES.paid].includes(this.args.invoice?.status);
  }

  get showEditButton() {
    let canUpdateSupplierInvoices = this.abilities.can('update supplierInvoices');

    let hasToReviewOrPaidStatus = [INVOICE_STATUSES.toReview, INVOICE_STATUSES.paid].includes(
      this.args.invoice?.status
    );

    return (
      hasToReviewOrPaidStatus && canUpdateSupplierInvoices && !this.args.invoice?.frenchEInvoicing
    );
  }

  get isFrenchEinvoicingDescriptionFormShown() {
    return (
      this.args.invoice?.frenchEInvoicing &&
      EDITABLE_DESCRIPTION_INVOICE_STATUSES.includes(this.args.invoice?.status)
    );
  }
}
