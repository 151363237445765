export default {
  "header-cell": "cGk",
  "header-content": "cGJ caption-bold",
  "active": "cGs",
  "empty": "cGF",
  "request-type": "cGg",
  "date": "cGD",
  "requester": "cGV",
  "amount": "cGL",
  "header-status": "cGT"
};
