export default {
  "insight-pill": "qXS",
  "insight-pill-figure": "qXc",
  "insight-pill-renderer": "qXq",
  "loading": "qXZ",
  "counter": "qXR",
  "error": "qXQ",
  "insight-pill-label": "qXe",
  "insight-pill-subtitle": "qXB caption",
  "hidden": "qXE",
  "missing-amounts-warning": "qXd",
  "missing-amounts": "qXI"
};
