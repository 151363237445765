export default {
  "details": "qPx",
  "details-header": "qPw",
  "details-content": "qPN",
  "details-footer": "qPv",
  "invoice-status-container": "qPo body-2",
  "invoice-payment-date": "qPn",
  "overdue": "qPi",
  "details-list": "qPA",
  "details-list-item": "qPY",
  "details-list-item-label": "qPh body-2",
  "details-list-item-value": "qPf",
  "border-top": "qPK",
  "description": "qPG",
  "description-value": "qPr",
  "edit-button": "qPb body-2",
  "edit-icon": "qPy",
  "badge-union": "qPU",
  "edit-description": "qPj"
};
