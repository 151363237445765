export default {
  "header-cell": "qJo",
  "header-content": "qJn caption-bold",
  "active": "qJi",
  "empty": "qJA",
  "align-right": "qJY",
  "type": "qJh",
  "requester": "qJf",
  "request-date": "qJK",
  "amount": "qJG",
  "status": "qJr"
};
