export default {
  "selector": "cnx",
  "pension-selector-content": "cnw",
  "withholding-tax-selector-content": "cnN",
  "withholding-tax-selector-content-row": "cnv",
  "power-select-field": "cno",
  "rate-field": "cnn",
  "stamp-revenue-field": "cni",
  "dropdown-option": "cnA",
  "dropdown-content": "cnY"
};
