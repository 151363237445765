export default {
  "form": "qlC",
  "form-section": "qlk",
  "form-title": "qlJ title2",
  "divider": "qls",
  "row": "qlF",
  "col": "qlg",
  "actions": "qlD",
  "buttons": "qlV"
};
