export default {
  "header-cell": "cjt",
  "empty": "cjM",
  "header-content": "cjP caption-bold",
  "active": "cjl",
  "header-type": "cjX",
  "header-reason": "cjC",
  "header-status": "cjk",
  "header-amount": "cjJ",
  "row-sidebar": "cjs",
  "hidden": "cjF",
  "animated": "cjg",
  "fadein-item": "cjD",
  "animated-cell": "cjV"
};
