export default {
  "invoice-sidebar": "qIg",
  "body": "qID",
  "box": "qIV",
  "box-header": "qIL",
  "sidebar-box": "qIT",
  "border-top": "qIz",
  "row": "qIa",
  "greyed-out": "qIH",
  "strike-through": "qIO"
};
