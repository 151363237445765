export default {
  "invoice-sidebar": "cAy",
  "body": "cAU",
  "box": "cAj",
  "box-header": "cAW",
  "footer": "cAu",
  "footer-button": "cYS",
  "sidebar-box": "cYc",
  "border-top": "cYq",
  "row": "cYZ",
  "status-container": "cYR",
  "due-date-container": "cYQ",
  "mark-as-btn": "cYe",
  "primary-actions": "cYB cYc",
  "primary-action": "cYE body-1",
  "button-icon": "cYd"
};
