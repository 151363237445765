export default {
  "iban": "qQU",
  "checkbox": "qQj",
  "toggle-content": "qQW",
  "beneficiary-name": "qQu",
  "category": "qeS",
  "beneficiary-email": "qec",
  "beneficiary-form": "qeq",
  "beneficiary-email-container": "qeZ"
};
