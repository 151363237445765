export default {
  "referral-invite-new": "cfU",
  "referral": "cfj",
  "center": "cfW",
  "illustration": "cfu",
  "share-referral": "cKS",
  "choice": "cKc",
  "line": "cKq",
  "email": "cKZ",
  "email-input": "cKR",
  "input-field--error": "cKQ",
  "error-message": "cKe",
  "external-link-icon": "cKB"
};
