export default {
  "mileage-validation": "qqh",
  "actions": "qqf",
  "action-btn": "qqK",
  "approve-btn": "qqG",
  "request-mileage-validation": "qqr",
  "account-selector": "qqb",
  "has-error": "qqy",
  "funds-disclaimer": "qqU",
  "account-options": "qqj",
  "account-balance": "qqW",
  "warnings": "qqu"
};
