export default {
  "row": "cuU",
  "empty": "cuj",
  "active": "cuW",
  "animated": "cuu",
  "fadein-item": "qSS",
  "cell": "qSc body-2",
  "cell-content": "qSq",
  "cell-amount": "qSZ",
  "cell-content-amount": "qSR body-1"
};
