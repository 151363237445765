/* import __COLOCATED_TEMPLATE__ from './status-tag.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

import { Tag } from '@repo/design-system-kit';

import { INVOICE_STATUSES, STATUS_COLORS } from 'qonto/constants/supplier-invoice';

export default class SupplierInvoicesStatusTagComponent extends Component {
  tag = Tag;

  @service intl;

  get status() {
    let { status, customText = {}, isFrenchEinvoice = false } = this.args;

    switch (status) {
      case INVOICE_STATUSES.scheduled:
        return {
          display:
            customText[INVOICE_STATUSES.scheduled] ??
            this.intl.t('supplier-invoices.preview.status.scheduled-payment'),
          color: STATUS_COLORS.scheduled,
        };
      case INVOICE_STATUSES.pending:
        return {
          display:
            customText[INVOICE_STATUSES.pending] ??
            this.intl.t('supplier-invoices.preview.status.pending-request'),
          color: STATUS_COLORS.pending,
        };
      case INVOICE_STATUSES.archived:
        return {
          display:
            customText[INVOICE_STATUSES.archived] ??
            this.intl.t('supplier-invoices.preview.status.archived'),
          color: STATUS_COLORS.archived,
        };
      case INVOICE_STATUSES.paid:
        return {
          display:
            customText[INVOICE_STATUSES.paid] ??
            this.intl.t('supplier-invoices.preview.status.paid'),
          color: STATUS_COLORS.paid,
        };
      case INVOICE_STATUSES.toReview:
        return {
          display:
            customText[INVOICE_STATUSES.toReview] ??
            (isFrenchEinvoice
              ? this.intl.t('supplier-invoices.preview.status.to-pay')
              : this.intl.t('supplier-invoices.preview.status.imported')),
          color: isFrenchEinvoice ? STATUS_COLORS.toPay : STATUS_COLORS.toReview,
        };
      case INVOICE_STATUSES.toPay:
        return {
          display:
            customText[INVOICE_STATUSES.toPay] ??
            (isFrenchEinvoice
              ? this.intl.t('supplier-invoices.preview.status.to-pay')
              : this.intl.t('supplier-invoices.section.tab.inbox')),
          color: isFrenchEinvoice ? STATUS_COLORS.toPay : STATUS_COLORS.inbox,
        };
      default:
        return {
          display:
            customText[INVOICE_STATUSES.inbox] ??
            this.intl.t('supplier-invoices.section.tab.inbox'),
          color: STATUS_COLORS.inbox,
        };
    }
  }
}
