export default {
  "wrapper": "cWa",
  "card-header": "cWH",
  "subtitle-caption": "cWO",
  "estimated-earnings": "cWm",
  "progress": "cWp",
  "progress-bar": "cWx",
  "progress-bar-text": "cWw",
  "subtitle": "cWN",
  "badge": "cWv",
  "core-information": "cWo",
  "secondary-information": "cWn",
  "remuneration-table": "cWi",
  "name": "cWA title-3",
  "separator": "cWY",
  "remuneration-line": "cWh",
  "german-disclaimer": "cWf",
  "table": "cWK",
  "table-right": "cWG"
};
