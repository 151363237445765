export default {
  "card-details-container": "qSl",
  "title": "qSX caption-bold mb-16",
  "section": "qSC",
  "divider": "qSk",
  "top-divider": "qSJ",
  "content": "qSs body-2",
  "description": "qSF",
  "justify-end": "qSg",
  "icon": "qSD ml-8"
};
