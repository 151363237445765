export default {
  "review": "qEp",
  "review-content": "qEx",
  "review-parameters": "qEw",
  "row": "qEN",
  "row__border": "qEv",
  "label": "qEo",
  "value": "qEn",
  "error": "qEi",
  "tooltip-icon": "qEA",
  "terms-link": "qEY"
};
