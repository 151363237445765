export default {
  "row": "qty",
  "disabled": "qtU",
  "cell": "qtj body-2",
  "cell-link": "qtW",
  "cell-content": "qtu",
  "item-truncate-text": "qMS",
  "item-name-cell": "qMc",
  "item-account-cell": "qMq",
  "icon": "qMZ",
  "icon-download": "qMR"
};
