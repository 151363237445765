export default {
  "team-card": "qFc",
  "card-link": "qFq",
  "shadow": "qFZ",
  "history-button": "qFR",
  "cards-button": "qFQ",
  "menu-item": "qFe",
  "subtitle": "qFB",
  "delete-team": "qFE"
};
