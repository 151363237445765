export default {
  "container": "cYL",
  "form-container": "cYT",
  "content": "cYz",
  "fields": "cYa",
  "input-settings": "cYH",
  "current-year": "cYO",
  "next-invoice-number": "cYm",
  "preview-container": "cYp",
  "actions": "cYx",
  "close-button": "cYw btn btn--icon-only btn--tertiary btn--large",
  "preview-image-container": "cYN",
  "preview-image": "cYv"
};
