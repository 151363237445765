export default {
  "row": "qQl",
  "active": "qQX",
  "dot": "qQC",
  "red": "qQk",
  "green": "qQJ",
  "yellow": "qQs",
  "status": "qQF",
  "align-right": "qQg",
  "animated": "qQD",
  "fadein-item": "qQV",
  "cell": "qQL body-2",
  "cell-content": "qQT",
  "amount": "qQz body-2",
  "subtitle": "qQa caption",
  "padding-left": "qQH",
  "padding-right": "qQO",
  "note": "qQm"
};
