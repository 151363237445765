export default {
  "container": "cif",
  "text-content": "ciK",
  "text-wrapper": "ciG",
  "actions-container": "cir",
  "illustration": "cib",
  "feature-tour-link": "ciy",
  "link-illustration": "ciU",
  "lottie": "cij"
};
