export default {
  "referral-invite": "cKp",
  "referral": "cKx",
  "illustration": "cKw",
  "steps": "cKN",
  "step": "cKv",
  "share-referral": "cKo",
  "choice": "cKn",
  "line": "cKi",
  "email": "cKA",
  "email-input": "cKY",
  "input-field--error": "cKh",
  "error-message": "cKf",
  "external-link-icon": "cKK"
};
