export default {
  "document": "cYj",
  "document-details": "cYW",
  "document-details-amount": "cYu body-2",
  "negative": "chS",
  "positive": "chc",
  "rejected": "chq",
  "document-icon": "chZ",
  "document-details-link": "chR body-link",
  "canceled": "chQ"
};
