export default {
  "header-cell": "qRx",
  "header-content": "qRw caption-bold",
  "active": "qRN",
  "empty": "qRv",
  "request-type": "qRo",
  "status": "qRn",
  "note": "qRi",
  "amount": "qRA"
};
