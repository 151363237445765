export default {
  "mileage-validation": "crK",
  "actions": "crG",
  "action-btn": "crr",
  "action-btn-success": "crb",
  "action-btn-right": "cry",
  "request-mileage-validation": "crU",
  "account-selector": "crj",
  "has-error": "crW",
  "funds-disclaimer": "cru",
  "account-options": "cbS",
  "account-balance": "cbc",
  "warnings": "cbq"
};
