export default {
  "item": "chl",
  "details": "chX",
  "flex": "chC",
  "header": "chk chC",
  "flex-no-gap": "chJ chC",
  "file-name": "chs",
  "label": "chF body-2",
  "self-icon": "chg",
  "with-separator": "chD",
  "dimmed": "chV"
};
