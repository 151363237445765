export default {
  "header-cell": "qkZ",
  "first-col": "qkR",
  "mid-col": "qkQ",
  "outstanding-col": "qke",
  "header-content": "qkB caption-bold",
  "active": "qkE",
  "quick-actions": "qkd",
  "empty": "qkI"
};
