export default {
  "modal-wrapper": "chL",
  "spinner-container": "chT",
  "spinner": "chz",
  "container": "cha",
  "tabs-item": "chH",
  "form-container": "chO",
  "fieldset": "chm",
  "preview-container": "chp",
  "switch-container": "chx",
  "pdf-preview-container": "chw",
  "form-content": "chN",
  "row": "chv",
  "numbering-format": "cho",
  "numbering-fields": "chn",
  "input-settings": "chi",
  "next-invoice-number": "chA",
  "spacing": "chY",
  "label-tooltip": "chh",
  "tooltip-icon": "chf",
  "customize-field": "chK",
  "button-container-footer": "chG"
};
