export default {
  "wrapper": "qeo",
  "header": "qen mb-48",
  "form-title": "qei title-2 mb-16",
  "form-subtitle": "qeA body-2",
  "form": "qeY",
  "section": "qeh mb-48",
  "section-title": "qef title-3 mb-16",
  "form-footer": "qeK",
  "footer-btn": "qeG",
  "transfer-form": "qer"
};
