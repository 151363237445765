export default {
  "insight-pill": "qlh",
  "interactive": "qlf",
  "focus": "qlK",
  "subtitle": "qlG caption",
  "hidden": "qlr",
  "missing-amounts-warning": "qlb",
  "totals": "qly",
  "counter": "qlU title-3",
  "loading": "qlj",
  "error": "qlW",
  "title": "qlu title-4"
};
