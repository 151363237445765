export default {
  "header": "qMU",
  "subtitle": "qMj body-2",
  "date": "qMW",
  "subtitle-error": "qMu",
  "card": "qPS",
  "card-header": "qPc",
  "line-placeholder": "qPq",
  "post-closing-list": "qPZ",
  "post-closing-container": "qPR",
  "post-closing-list-element": "qPQ",
  "free-trial-disclaimer": "qPe",
  "description": "qPB body-2",
  "mw-100": "qPE",
  "addon-overview": "qPd",
  "addons-container": "qPI"
};
