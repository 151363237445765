export default {
  "header": "cyD",
  "header-type": "cyV",
  "close": "cyL",
  "close-icon": "cyT",
  "header-infos": "cyz",
  "title": "cya body-1",
  "declined": "cyH",
  "canceled": "cyO",
  "approved": "cym",
  "pending": "cyp",
  "date": "cyx body-2",
  "container": "cyw",
  "picto": "cyN",
  "picto-status": "cyv",
  "general": "cyo",
  "amount": "cyn",
  "counterparty": "cyi body-2",
  "initiator": "cyA",
  "initiator-avatar": "cyY",
  "avatar-image": "cyh",
  "initiator-id": "cyf",
  "name": "cyK body-2",
  "role": "cyG caption-bold",
  "infobox": "cyr",
  "account-infobox": "cyb cyr",
  "beneficiary-warning": "cyy caption-bold",
  "dates-wrapper": "cyU",
  "dates": "cyj",
  "recurrence": "cyW caption-bold",
  "arrow": "cyu",
  "notify-checkbox": "cUS"
};
