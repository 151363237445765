export default {
  "refunds-total": "qPC",
  "switch-recurrence": "qPk",
  "flex-wrapper": "qPJ",
  "promo-code": "qPs",
  "promo-code-total": "qPF",
  "others": "qPg",
  "others-total": "qPD",
  "subscription-detail": "qPV",
  "total-vat": "qPL",
  "fee-description": "qPT"
};
