export default {
  "payment-details": "cia",
  "label-payment-conditions": "ciH",
  "dropdown-content": "ciO",
  "dropdown-option": "cim",
  "activate-sdd-container": "cip",
  "activate-sdd-header": "cix",
  "selector-toggle-sdd": "ciw",
  "selector-toggle-content": "ciN",
  "selector-toggle": "civ",
  "new-badge": "cio",
  "new-badge-activation": "cin"
};
