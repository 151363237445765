export default {
  "row": "cGz",
  "active": "cGa",
  "animated": "cGH",
  "fadein-item": "cGO",
  "cell": "cGm",
  "cell-content": "cGp",
  "row-sidebar": "cGx",
  "empty": "cGw",
  "cell-amount": "cGN",
  "subtitle": "cGv caption",
  "hidden": "cGo",
  "cell-approve": "cGn",
  "quick-actions": "cGi",
  "account-select": "cGA",
  "note": "cGY",
  "cell-content-amount": "cGh body-1",
  "cell-status": "cGf"
};
