export default {
  "container": "cfZ",
  "content": "cfR",
  "input-row": "cfQ",
  "email-row": "cfe",
  "button-row": "cfB",
  "btn-edit": "cfE",
  "input-column": "cfd",
  "form-btn-row": "cfI",
  "border-bottom": "cft",
  "avatar-input": "cfM"
};
