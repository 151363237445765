export default {
  "row": "cfO",
  "cell": "cfm",
  "greyed-out": "cfp",
  "struck-through": "cfx",
  "flex-cell": "cfw",
  "overdue": "cfN",
  "align-right": "cfv",
  "customer-name-container": "cfo",
  "icon": "cfn",
  "warning": "cfi",
  "with-separator": "cfA",
  "align-center": "cfY"
};
