export default {
  "request-transfer-validation": "qej",
  "account-selector": "qeW",
  "has-error": "qeu",
  "actions": "qBS",
  "action-btn": "qBc",
  "warnings": "qBq",
  "top-border": "qBZ",
  "account-selector-subtitle": "qBR",
  "action-btn-right": "qBQ",
  "funds-disclaimer": "qBe",
  "account-options": "qBB",
  "account-balance": "qBE",
  "request-multi-transfer-validation": "qBd",
  "wrapper": "qBI",
  "warning-message-row": "qBt",
  "multi-transfer-align": "qBM",
  "button-approve": "qBP",
  "button-approve-spinner": "qBl",
  "button-decline": "qBX"
};
