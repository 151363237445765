export default {
  "savings-account": "qBG",
  "closed": "qBr",
  "badge": "qBb",
  "subtitle": "qBy",
  "amount": "qBU",
  "processing": "qBj",
  "gain": "qBW",
  "active": "qBu",
  "progress": "qES",
  "progress-bar": "qEc",
  "progress-text": "qEq",
  "info": "qEZ",
  "menu-item": "qER body-2",
  "close-account": "qEQ"
};
