export default {
  "content": "cWr",
  "balance": "cWb",
  "duration": "cWy",
  "green-text": "cWU",
  "balance-subtitle": "cWj",
  "balance-duration": "cWW",
  "footer": "cWu",
  "progress": "cuS",
  "progress-bar": "cuc",
  "progress-bar-text": "cuq",
  "progress-active": "cuZ"
};
