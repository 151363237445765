export default {
  "wrapper": "qeF",
  "beneficiary": "qeg",
  "name": "qeD body-1",
  "beneficiary-email": "qeV body-2",
  "dropdown-icon": "qeL",
  "trusted": "qeT",
  "account-number": "qez flex body-2",
  "account-icon": "qea",
  "m-chip": "qeH",
  "dropdown": "qeO body-2",
  "delete-option": "qem",
  "beneficiary-name": "qep"
};
