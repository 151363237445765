export default {
  "section-container": "qqR",
  "disclaimer-section": "qqQ",
  "trip-title": "qqe caption-bold mb-16",
  "google-link-container": "qqB",
  "link-icon": "qqE",
  "trip-details": "qqd body-2",
  "trip-details-icon": "qqI",
  "trip-details-title": "qqt",
  "subtitle": "qqM caption-bold mb-16",
  "disclaimer": "qqP",
  "visualizer": "qql"
};
