export default {
  "sections": "qeM",
  "actions": "qeP",
  "search-input": "qel",
  "no-upload": "qeX",
  "new-link": "qeC body-2",
  "between-links": "qek",
  "action-bar": "qeJ",
  "empty-state": "qes"
};
