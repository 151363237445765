export default {
  "row": "qkP",
  "greyed-out": "qkl",
  "supplier-name": "qkX",
  "cell": "qkC",
  "supplier": "qkk",
  "email-address": "qkJ",
  "outstanding-balance": "qks",
  "quick-actions": "qkF",
  "empty": "qkg",
  "supplier-container": "qkD",
  "supplier-avatar": "qkV",
  "grey-background": "qkL"
};
