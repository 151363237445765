export default {
  "row": "cbO body-1",
  "active": "cbm",
  "cell": "cbp",
  "empty": "cbx",
  "ellipsis": "cbw",
  "text-secondary": "cbN",
  "transfer-type": "cbv",
  "transfer-type__icon": "cbo",
  "quick-actions": "cbn",
  "amount": "cbi",
  "cell-approve": "cbA",
  "account-select": "cbY",
  "row-compact": "cbh",
  "cell-amount": "cbf",
  "cell-content-receipt": "cbK body-1",
  "cell-date": "cbG",
  "row-sidebar": "cbr",
  "hidden": "cbb",
  "animated": "cby",
  "fadein-item": "cbU",
  "animated-cell": "cbj",
  "note": "cbW"
};
