export default {
  "wrapper": "cNP",
  "per-page-container": "cNl",
  "options": "cNX",
  "option": "cNC",
  "active": "cNk",
  "description": "cNJ",
  "description-per-page": "cNs",
  "summary": "cNF"
};
