export default {
  "header-cell": "qJb",
  "header-content": "qJy caption-bold",
  "active": "qJU",
  "empty": "qJj",
  "type": "qJW",
  "requester": "qJu",
  "request-date": "qsS",
  "amount": "qsc",
  "receipt": "qsq",
  "quick-actions": "qsZ"
};
