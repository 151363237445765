export default {
  "header": "qqX",
  "header-type": "qqC",
  "close": "qqk",
  "close-icon": "qqJ",
  "header-infos": "qqs",
  "title": "qqF body-1",
  "declined": "qqg",
  "canceled": "qqD",
  "approved": "qqV",
  "pending": "qqL",
  "date": "qqT body-2",
  "mileage-icon": "qqz",
  "distance-subtitle": "qqa body-2",
  "link-icon": "qqH",
  "container": "qqO",
  "picto": "qqm",
  "general": "qqp",
  "amount": "qqx",
  "calculation-container": "qqw",
  "counterparty": "qqN body-2",
  "infobox": "qqv"
};
