export default {
  "card": "coV",
  "error": "coL",
  "customer-info": "coT",
  "edit-customer-tooltip": "coz",
  "title": "coa",
  "flex-row": "coH",
  "dot-separator": "coO",
  "flex-span": "com",
  "faq-link": "cop",
  "tooltip-wrapper": "cox",
  "badge-union": "cow"
};
