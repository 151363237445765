export default {
  "header-cell": "qRk",
  "header-content": "qRJ caption-bold",
  "active": "qRs",
  "empty": "qRF",
  "align-right": "qRg",
  "request-type": "qRD",
  "requester": "qRV",
  "amount": "qRL"
};
