export default {
  "header-cell": "cbP",
  "empty": "cbl",
  "header-type": "cbX",
  "header-requester": "cbC",
  "header-date": "cbk",
  "header-amount": "cbJ",
  "header-approval": "cbs",
  "header-content": "cbF caption-bold",
  "active": "cbg",
  "header-receipt": "cbD",
  "row-compact": "cbV",
  "row-sidebar": "cbL",
  "hidden": "cbT",
  "animated": "cbz",
  "fadein-item": "cba",
  "animated-cell": "cbH"
};
