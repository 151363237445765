export default {
  "row": "qRY",
  "active": "qRh",
  "dot": "qRf",
  "red": "qRK",
  "green": "qRG",
  "yellow": "qRr",
  "status": "qRb",
  "align-right": "qRy",
  "animated": "qRU",
  "fadein-item": "qRj",
  "cell": "qRW body-2",
  "cell-content": "qRu",
  "amount": "qQS body-1",
  "subtitle": "qQc caption",
  "no-padding": "qQq"
};
