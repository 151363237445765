export default {
  "sidepanel": "qCN",
  "close-icon": "qCv",
  "close-button": "qCo",
  "supplier-card": "qCn",
  "supplier-card-details": "qCi",
  "supplier-name": "qCA title-3 mb-8",
  "supplier-card-placeholder": "qCY",
  "box": "qCh",
  "box-element": "qCf",
  "related-invoices-title": "qCK",
  "placeholder-container": "qCG",
  "related-invoices-wrapper": "qCr",
  "related-invoice-placeholder": "qCb",
  "related-invoice-placeholder-inner": "qCy",
  "actions": "qCU",
  "currency": "qCj"
};
