export default {
  "header": "cUD",
  "header-type": "cUV",
  "close": "cUL",
  "close-icon": "cUT",
  "header-infos": "cUz",
  "title": "cUa body-1",
  "declined": "cUH",
  "canceled": "cUO",
  "approved": "cUm",
  "pending": "cUp",
  "date": "cUx body-2",
  "mileage-icon": "cUw",
  "distance-subtitle": "cUN body-2",
  "link-icon": "cUv",
  "container": "cUo",
  "picto": "cUn",
  "general": "cUi",
  "amount": "cUA",
  "calculation-container": "cUY",
  "counterparty": "cUh body-2",
  "infobox": "cUf"
};
