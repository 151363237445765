export default {
  "row": "cwY",
  "cell": "cwh",
  "title": "cwf",
  "internal-note": "cwK",
  "unit": "cwG",
  "price": "cwr",
  "quick-actions": "cwb",
  "empty": "cwy",
  "checkbox": "cwU"
};
