/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import { BadgeHighlight, Disclaimer } from '@repo/design-system-kit';
import { reads } from 'macro-decorators';

import { registerJsURL } from 'qonto/constants/hosts';
import { PRICE_PLAN_LINEUPS } from 'qonto/constants/price-plan';
import { SUBSCRIPTION_RECURRENCES, TRACKING_TRIAL_STATE } from 'qonto/constants/subscriptions';
import { HeaderAddonsOverview } from 'qonto/react/components/subscription/header/addons-overview';
import { SubscriptionHeaderDeactivated } from 'qonto/react/components/subscription/header/deactivated';

export default class SubscriptionHeaderComponent extends Component {
  disclaimerInline = Disclaimer.Inline;

  badgeHighlight = BadgeHighlight;
  subscriptionHeaderDeactivated = SubscriptionHeaderDeactivated;
  HeaderAddonsOverview = HeaderAddonsOverview;

  @service organizationManager;
  @service subscriptionManager;
  @service intl;
  @service abilities;
  @service flowLinkManager;
  @service segment;

  @tracked deactivationRequester = this.args.deactivationRequester;
  @tracked deactivated = this.args.deactivated;

  @reads('subscriptionManager.currentSubscription') currentSubscription;
  @reads('subscriptionManager.currentPricePlan') currentPlan;
  @reads('organizationManager.membership.role') role;
  @reads('organizationManager.organization') organization;

  registerLink = `${registerJsURL}/signup/success`;

  get planOverviewDescription() {
    let isAnnual =
      this.subscriptionManager.currentSubscription?.recurrence === SUBSCRIPTION_RECURRENCES.ANNUAL;
    if (isAnnual) {
      return {
        part1: this.intl.t(
          'subscription.consumption-table.plan-overview.plan.description.annual.part-1',
          {
            planName: this.currentPlan.localName,
            htmlSafe: true,
          }
        ),
        part2: this.intl.t(
          'subscription.consumption-table.plan-overview.plan.description.annual.part-2'
        ),
      };
    }

    return {
      part1: this.intl.t('subscription.consumption-table.plan-overview.plan.description.part-1', {
        planName: this.currentPlan.localName,
        htmlSafe: true,
      }),
      part2: this.intl.t('subscription.consumption-table.plan-overview.plan.description.part-2'),
    };
  }

  get trialInfo() {
    if (this.hasModularPricing) {
      return this.subscriptionManager.currentSubscription?.availableTrials.find(
        trial => trial.productType === 'core'
      );
    } else {
      return this.subscriptionManager.currentSubscription?.availableTrials[0];
    }
  }

  get monthDuration() {
    return this.trialInfo.monthDuration;
  }

  get nextSubscriptionBillingDateFormatted() {
    return dateToken({
      date: this.currentSubscription?.nextSubscriptionBillingDate,
      locale: this.intl.primaryLocale,
      token: DATE_FORMAT_TOKENS.DATE_YEAR_L,
    });
  }

  get isActiveTrial() {
    if (this.hasModularPricing) {
      return Boolean(this.currentSubscription?.activeTrial?.previous_product_id);
    } else {
      return Boolean(this.currentSubscription?.activeTrial?.previous_plan_id);
    }
  }

  get activeTrial() {
    return this.currentSubscription?.activeTrial;
  }

  get defaultLineup() {
    return this.currentPlan.lineup || PRICE_PLAN_LINEUPS.SOLO;
  }

  @action onDeactivationCanceled() {
    this.deactivationRequester = null;
  }

  get hasModularPricing() {
    return this.organization?.hasModularPricing;
  }

  get canSeeAddonOverview() {
    return (
      this.hasModularPricing &&
      this.abilities.can('update subscription') &&
      this.abilities.can('view subscription') &&
      !this.deactivated &&
      !this.deactivationRequester
    );
  }

  @action
  handleAddonClick(trackingId) {
    this.segment.track('addon_management_entrypoint_clicked', {
      price_plan: this.currentPlan.code,
      price_plan_recurrence: this.currentSubscription.recurrence,
      trial_state: this.currentSubscription.hasInitialTrial
        ? TRACKING_TRIAL_STATE.INITIAL_TRIAL
        : TRACKING_TRIAL_STATE.NONE,
      has_active_addon: this.subscriptionManager.currentAddons?.length > 0,
      origin: trackingId,
    });

    this.flowLinkManager.transitionTo({
      name: 'addon-change',
      stepId: 'addons',
    });
  }
}
