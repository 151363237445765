export default {
  "modal": "che",
  "action-container": "chB",
  "similar-label": "chE",
  "info-icon": "chd",
  "container": "chI",
  "subtitle": "cht caption-bold",
  "error": "chM",
  "item-container": "chP"
};
