export default {
  "bank-account-option": "qss",
  "row": "qsF",
  "active": "qsg",
  "animated": "qsD",
  "fadein-item": "qsV",
  "cell": "qsL body-2",
  "cell-content": "qsT",
  "subtitle": "qsz caption",
  "no-padding": "qsa",
  "empty": "qsH",
  "note": "qsO",
  "cell-content-amount": "qsm body-1",
  "cell-content-receipt": "qsp body-1",
  "cell-quick-actions": "qsx",
  "account-selector": "qsw",
  "cell-type": "qsN"
};
