export default {
  "sidebar": "cAn",
  "body": "cAi",
  "form": "cAA",
  "form-section": "cAY",
  "form-title": "cAh title2",
  "row": "cAf",
  "payment-details": "cAK",
  "col": "cAG",
  "footer": "cAr",
  "footer-button": "cAb"
};
