export default {
  "row": "qsR",
  "active": "qsQ",
  "dot": "qse",
  "red": "qsB",
  "green": "qsE",
  "yellow": "qsd",
  "align-right": "qsI",
  "animated": "qst",
  "fadein-item": "qsM",
  "cell": "qsP body-2",
  "cell-content": "qsl",
  "amount": "qsX body-1",
  "subtitle": "qsC caption",
  "status": "qsk",
  "cell-amount": "qsJ"
};
