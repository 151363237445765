export default {
  "row": "qQZ",
  "active": "qQR",
  "animated": "qQQ",
  "fadein-item": "qQe",
  "cell": "qQB body-2",
  "cell-content": "qQE",
  "subtitle": "qQd caption",
  "no-padding": "qQI",
  "note": "qQt",
  "cell-amount": "qQM",
  "cell-content-amount": "qQP body-1"
};
